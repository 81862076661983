import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { hasAuthToken } from '../actions/_auth'

import styled from "@emotion/styled"
const BigLogoImage = styled.img`
  width: 300px;
`

class IndexPage extends React.Component {

  componentDidMount() {
    if(hasAuthToken() === true) {
      window.location = '/app'
    }
  }

  render() {
    return(
      <Layout>
        <SEO title="Home" />
        <div className="index-page d-flex flex-column">
          <div>
            <BigLogoImage src={`https://s3-ap-southeast-1.amazonaws.com/pblcfyls/harddiskcloud/big-logo.png`} alt="Harddisk Cloud" className="main-image" />
          </div>
          <div>
            <Link to="/auth/signin" className="btn btn-lg btn-primary">Get Started</Link>
          </div>
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {}
export default IndexPage
